


.chatpage-main-block {
    display: flex;
    flex-direction: row;
    height: 100vh;
    .global-error-main {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        background-color: #FFFFFF;
        opacity: 1;
        visibility: visible;
        transition: 0.3s;
        .global-error-block {
            display: flex;
            flex-direction: row;
            gap: 15px;
            max-width: 650px;
            button {
                border: none;
                padding: 10px 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 7px;
                cursor: pointer;
                transition: 0.2s;
                &:hover {
                    opacity: 0.8;
                }
                svg {
                    width: 25px;
                    height: auto;
                    aspect-ratio: 1/1;
                }
            }
        }
    }
    .popup {
        .vacancies-selector-header {
            width: 100%;
            font-size: 15px;
            text-align: center;
            font-weight: 600;
        }
        .vacancy-list {
            margin: 15px 10px 15px 10px;
            gap: 15px;
            display: flex;
            flex-direction: column;

            .vacancy {
                cursor: pointer;
                padding: 8px 15px 8px 15px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 1px;
                border-radius: 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-size: 15px;
                transition: 0.2s;
                &:hover {
                    background-color: #d8f7ff;
                }
                .vacancy-name {
                    font-weight: 600;
                }
                .vacancy-city {}
            }
            .vacancies-not-found {
                display: flex;
                justify-content: center;
            }
        }
    }
    .chat-block {
        width: 100%;
        height: 100%;
        flex-direction: column;
        .chat-body {
            // 20px - отступы, 5px - margin-top у textarea, 1px - border-top
            min-height: calc(100% - 20px - 5px - 1px - 150px);
            overflow-y: scroll;
            padding-bottom: 15px;
            padding-left: 20px;
            padding-right: 20px;
            background: url("../../assets/chat-bg.png");
            background-size: contain;
            .chat-center-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
            .chat-clear-message {
                border-radius: 15px;
                font-size: 15px;
                width: fit-content;
                padding: 7px 13px 5px 13px;
                background-color: rgba(0, 0, 0, 0.4);
                color: white;
                font-weight: 600;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .negotiation-send-button {
                    margin-top: 3px;
                    margin-bottom: 5px;
                    font-weight: 400;
                    padding: 2px 10px 1px 10px;
                    width: fit-content;
                    background: rgba(255, 255, 255, 0.95);
                    border: none;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: 0.2s;
                    cursor: pointer;
                    &:hover {
                        background: rgba(224, 224, 224, 0.95);
                    }
                }
            }
            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }
            &::-webkit-scrollbar-track {
                display: none;
            }
            &::-webkit-scrollbar-thumb{
                border-radius: 10px;
                background: transparent;
            }
            &::-webkit-scrollbar-thumb:hover{
                background: transparent;
            }
            &::-webkit-scrollbar-thumb:active{
                background: transparent;
            }
            &:hover {
                &::-webkit-scrollbar-thumb{
                    border-radius: 10px;
                    background: rgba(0,0,0,0.2);
                }
                &::-webkit-scrollbar-thumb:hover{
                    background: rgba(0,0,0,0.4);
                }
                &::-webkit-scrollbar-thumb:active{
                    background: rgba(0,0,0,.9);
                }
            }
            .chat-row {
                width: 100%;
                margin-top: 15px;
                .message {
                    flex-direction: column;
                    width: fit-content;
                    min-width: 80px;
                    max-width: 450px;
                    word-wrap: break-word;
                    padding: 9px 14px 7px 13px;
                    border-radius: 15px;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                    .message-text {
                        font-size: 15px;
                        line-height: 19.5px;
                        white-space: pre-line;
                    }
                    .message-not-allowed {
                        color: rgba(0, 0, 0, 0.6);
                    }
                    .message-info {
                        display: flex;
                        flex-direction: row-reverse;
                        font-size: 10px;
                        flex-wrap: nowrap;
                        color: #595959;
                        .message-read-status {
                            margin-left: 4px;
                            display: flex;
                            svg {
                                width: 12px;
                                height: auto;
                                aspect-ratio: 1/1;
                            }
                        }
                        .status-read {
                            color: #2fc6f6;
                        }
                    }
                }
                .message-left {
                    background-color: white;
                }
                .message-right {
                    margin-left: auto;
                    margin-right: 0;
                    background-color: #e9fecb;
                }
            }
            .last-chat-element {
                height: 0;
            }
            .local-chat-error {
                margin-top: 10px;
            }
        }
        .chat-input {
            display: flex;
            flex-direction: row;
            padding: 10px 20px;
            border-top: 0.3px rgba(0,0,0,.12) solid;
            textarea {
                margin-top: 5px;
                max-height: 150px;
                padding: 0;
                font-size: 17px;
                outline:none;
                width: 100%;
                border: 0;
                background: none;
                &:disabled {
                    background: none;
                    &::placeholder {
                        text-align: center;
                        color: black;
                    }
                }
                &::-webkit-scrollbar {
                    width: 6px;
                    height: 6px;
                }
                &::-webkit-scrollbar-track {
                    display: none;
                }
                &::-webkit-scrollbar-thumb{
                    border-radius: 10px;
                    background: transparent;
                }
                &::-webkit-scrollbar-thumb:hover{
                    background: transparent;
                }
                &::-webkit-scrollbar-thumb:active{
                    background: transparent;
                }
                &:hover {
                    &::-webkit-scrollbar-thumb{
                        border-radius: 10px;
                        background: rgba(0,0,0,0.2);
                    }
                    &::-webkit-scrollbar-thumb:hover{
                        background: rgba(0,0,0,0.4);
                    }
                    &::-webkit-scrollbar-thumb:active{
                        background: rgba(0,0,0,.9);
                    }
                }
                &:active {
                    border: 0;
                }
            }
            button {
                margin-left: 15px;
                width: 50px;
                padding: 0;
                background: none;
                border: 0;
                color: #2fc6f6;
                display: flex;
                justify-content: right;
                align-items: flex-end;
                transition: .2s;
                cursor: pointer;
                svg {
                    width: 30px;
                    height: auto;
                    aspect-ratio: 1/1;
                }
                &:disabled {
                    cursor: auto;
                    opacity: 0;
                }
            }
        }
    }
    .profile-info-block {
        border-left: 0.3px rgba(0,0,0,.12) solid;
        width: 500px;
        height: 100%;
        overflow: hidden;
        .profile-main-info {
            margin-top: 5%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;
            width: 100%;
            padding-left: 35px;
            padding-right: 15px;
            padding-bottom: 5%;
            box-shadow: 0px 6px 12px -1px rgba(34, 60, 80, 0.2);

            .profile-photo {
                width: 75px;
                height: auto;
                aspect-ratio: 1/1;
                border-radius: 50%;
                overflow: hidden;
                .profile-photo-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .profile-fio-and-resume {
                margin-left: 25px;
                display: flex;
                flex-direction: column;
                .profile-fio {
                    font-weight: 600;
                    font-size: 18px;
                    width: 200px;
                    word-wrap: break-word;
                }
                .profile-resume {
                    font-size: 15px;
                }
            }
        }
        .info-blocks {
            margin-top: 25px;
            box-shadow: rgba(100, 100, 111, 0.2) 7px 7px 29px 0px;
            display: flex;
            flex-direction: column;
            gap: 25px;
            padding: 20px 0;
            .info-block {
                display: flex;
                flex-direction: row;
                height: fit-content;
                width: 100%;
                padding: 0 35px;
                .info-block-icon {
                    display: flex;
                    width: 50px;
                    height: auto;
                    aspect-ratio: 1/1;
                    align-items: flex-start;
                    justify-content: center;
                    color: rgba(0, 0, 0, 0.7);
                    svg {
                        margin-top: 5px;
                        width: 30px;
                        height: auto;
                        aspect-ratio: 1/1;
                    }
                }
                .info-block-data {
                    margin-left: 20px;
                    width: 100%;
                    .info-block-value {
                        font-size: 15px;
                    }
                    .info-block-name {
                        font-size: 15px;
                        color: rgba(0, 0, 0, 0.5);
                    }
                }
            }
        }
        .actions-blocks {
            margin-top: 25px;
            box-shadow: rgba(100, 100, 111, 0.2) 7px 7px 29px 0px;
            display: flex;
            flex-direction: column;
            button {
                display: flex;
                flex-direction: row;
                width: 100%;
                padding: 0 0 0 35px;
                justify-content: left;
                align-items: center;
                color: black;
                font-size: 15px;
                height: 40px;
                border: none;
                cursor: pointer;
                background-color: white;
                transition: background-color 0.2s;
                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }
                .action-block-icon {
                    display: flex;
                    height: auto;
                    aspect-ratio: 1/1;
                    align-items: center;
                    justify-content: center;
                    color: rgba(0, 0, 0, 0.7);
                    width: 50px;
                    svg {
                        width: 20px;
                        height: auto;
                        aspect-ratio: 1/1;
                    }
                }
                .action-block-name {
                    margin-left: 10px;
                }
            }
        }
    }
}

body {
    padding: 0;
}
