.InputFromUI{
    display: flex;
    flex-direction: column;
    width: 100%;

    .title{
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
        color: #000000;
        margin-bottom: 5px;
        max-width: 95%;

        .req{
            color: red;
        }
    }
    .select-comp{
        width: 100%;
        line-height: 130%;
        outline: none;
        border: 0px solid red;
        border-radius: 7px;
        transition: 300ms;

        &.error{
            border: 1px solid red;
        }

        &::placeholder{
            color: var(--placeholde-color);
        }

        .select-component__control{
            width: 100%;
            min-height: 35px;
            padding: 7px 10px;
            background: rgba(0, 0, 0, 0.05);
            color: #000000;
            border-radius: 5px;
            box-sizing: border-box;
            font-weight: 400;
            font-size: 15px;
            outline: none;
            border: none;
            box-shadow: none;

            .select-component__value-container{
                width: 100%;
                height: 100%;
                padding: 0;

                .select-component__input-container{
                    color: #000000;
                }

                .select-component__multi-value{
                    background-color: #8de4f1;
                    padding: 3px ;
                    padding-left: 6px ;
                    border-radius: 2px ;
                    margin: 2px ;

                    .select-component__multi-value__label{
                        color: var(--apply-text-color);
                        border-radius: 2px ;
                        font-size: 12px ;
                        padding: 3px ;
                        padding-left: 6px ;
                    }

                    .select-component__multi-value__remove{
                        border-radius: 2px ;
                        padding-left: 4px ;
                        padding-right: 4px ;
                        cursor: pointer;
                        background-color: transparent;

                        svg{
                            height: 14px ;
                            width: 14px ;
                            fill: var(--main-bg-color);
                            stroke: var(--main-bg-color);
                        }
                    }
                }

                .select-component__single-value{
                    color: #000000;
                    margin-left: 2px ;
                    margin-right: 2px ;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }

                .select-component__input-container{
                    margin: 0;
                    padding-bottom: 0;
                    padding-top: 0;
                }
            }

            .select-component__indicators{
                //display: none;
                height: auto;
                .select-component__clear-indicator {
                    padding: 0;
                    svg {
                        height: 20px;
                        width: auto;
                    }
                }
                .select-component__dropdown-indicator, .select-component__indicator-separator {
                    display: none;
                }

            }
        }

        .select-component__menu{
            z-index: 9999999999;
            font-weight: 400;
            font-size: 15px;
            background-color: #f1f1f1;
            overflow: hidden;
            box-sizing: border-box;
            margin-bottom: 8px ;
            margin-top: 8px ;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

            .select-component__menu-list{
                padding: 0;
                margin: 0;
                max-height: 300px;

                &::-webkit-scrollbar{
                    width: 3px;
                    background-color: transparent;
                    padding: 2px;
                }

                &::-webkit-scrollbar-thumb{
                    background-color: rgba(0, 0, 0, 0.3);
                    opacity: 0.5;
                    border-radius: 30px ;
                }

                .select-component__option{

                    padding: 8px 12px ;

                    &:hover{
                        color: var(--apply-text-color);
                        background-color: rgba(0, 0, 0, 0.2);
                    }
                }

                .select-component__option--is-selected{
                    background-color: rgba(0, 0, 0, 0.1);
                    color: var(--apply-text-color);
                }

                .select-component__option--is-focused{
                    background-color: rgba(0, 0, 0, 0.2);;
                    color: var(--apply-text-color);
                }
            }
        }
    }
    .errorText{
        color: red;

        P{
            font-size: 12px ;
            line-height: 130%;
            font-weight: 400;
        }
    }

    &.loading{
        .load-form{
            width: 100%;
            height: 48px ;
            opacity: 0.3;

            span{
                width: 100%;
                height: 100%;
                border-radius: 7px ;
            }
        }
    }
}