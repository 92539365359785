.popup-hide {
    opacity: 0;
    pointer-events: none;
    transition: .2s;
}


.popup-background {
    transition: .2s;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(173, 173, 173, 0.42);
    .popup {
        -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
        box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
        border-radius: 7px;
        width: 800px;
        max-height: 70vh;
        position: fixed;
        background-color: white;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 20px 30px;
        height: 100%;
        overflow: hidden;
        .popup-header {
            display: flex;
            flex-direction: row-reverse;
            .popup-close {
                button {
                    font-size: 23px;
                    border: 0;
                    background: none;
                    padding: 0;
                    margin: 0;
                    color: #000000;
                    transition: .2s;
                    &:hover {
                        cursor: pointer;
                        opacity: 50%;
                        transition: .2s;
                    }
                }
            }
        }
        .popup-data {
            margin-top: 7px;
            height: calc(100% - 20px - 23px - 7px);
            .popup-data-inner {
                height: 100%;
                overflow-y: auto;
                &::-webkit-scrollbar {
                    width: 6px;
                    height: 6px;
                }
                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background: rgba(0,0,0,0.1);
                }
                &::-webkit-scrollbar-thumb{
                    border-radius: 10px;
                    background: rgba(0,0,0,0.2);
                }
                &::-webkit-scrollbar-thumb:hover{
                    background: rgba(0,0,0,0.4);
                }
                &::-webkit-scrollbar-thumb:active{
                    background: rgba(0,0,0,.9);
                }
                padding-right: 5px;
            }
        }
    }
}
